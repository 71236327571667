// 开发环境域名

// const host_development = "https://php-b2cplus.yixiangonline.com";
const host_development = "https://wr.yanjia0593.com";
export default {
  // 版本
  version: "2.4.1",
  baseURL: process.env.NODE_ENV == "production" ? "" : host_development,
  // baseURL:host_development,
};
